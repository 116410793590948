import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import BlogListEntry from "../components/blog-list-entry"
import { css } from "@emotion/core"

const linkCss = css`
  & {
    margin-left: 0.5rem;
  }
  &:first-of-type {
    margin-left: 0;
  }
`
const BlogList = ({ data, pageContext }) => {
  const { currentPage, numPages, slugBase } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? slugBase : `${slugBase}/${currentPage - 1}`
  const nextPage = `${slugBase}/${currentPage + 1}`
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout>
      {posts.map(({ node }) => {
        return (
          <BlogListEntry
            key={node.id}
            title={node.frontmatter.title}
            bodyHtml={node.html}
            created={node.frontmatter.date}
          />
        )
      })}
      <div>
        {!isFirst && (
          <Link css={linkCss} to={prevPage} rel="prev">
            ← Previous Page
          </Link>
        )}
      </div>
      <div>
        {Array.from({ length: numPages }, (_, i) => (
          <Link
            css={linkCss}
            key={`pagination-number${i + 1}`}
            to={`${slugBase}/${i + 1}`}
          >
            {i + 1}
          </Link>
        ))}
      </div>
      <div>
        {!isLast && (
          <Link css={linkCss} to={nextPage} rel="next">
            Next Page →
          </Link>
        )}
      </div>
      <div>
        <Link to="/">Go back to the homepage</Link>
      </div>
    </Layout>
  )
}
export default BlogList

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      filter: { frontmatter: { category: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import * as moment from "moment"
import { css } from "@emotion/core"

const titleCss = css`
  margin-bottom: 0;
`
const frontmatterCss = css`
  opacity: 0.8;
  font-size: 0.8rem;
  margin-top: 0;
  margin-bottom: 1rem;
`
const BlogListEntry = ({ title, bodyHtml, created, author }) => {
  const postDateFormat = "MMMM Do YYYY"
  return (
    <div
      css={css`
        padding: 1.5rem 0;
      `}
    >
      <h2 css={titleCss}>{title}</h2>
      <p css={frontmatterCss}>
        Posted by {author} on {moment(created).format(postDateFormat)}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: bodyHtml,
        }}
      />
    </div>
  )
}

BlogListEntry.propTypes = {
  title: PropTypes.string.isRequired,
  bodyHtml: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  author: PropTypes.string,
}

BlogListEntry.defaultProps = {
  author: `Irene`,
}

export default BlogListEntry
